import { configureStore } from '@reduxjs/toolkit'
import { uiSlice } from '../common/uiSlice'
import { populaApi } from '../features/apis/apiSlice'
import { appSlice } from "../features/apis/appSlice"
import { forecastSlice } from '../features/apis/forecastApi'

export const store = configureStore({
  reducer: {
    [populaApi.reducerPath]: populaApi.reducer,
    [forecastSlice.name]: forecastSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [uiSlice.name]: uiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(populaApi.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch